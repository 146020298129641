














































































import Vue from 'vue'
import Component from 'vue-class-component'
import PageDetails from "@/modules/genprox/modules/AIFM/modules/reg-reporting/components/PageDetails.vue";
import XmlPageDetails from "@/modules/genprox/modules/AIFM/modules/reg-reporting/components/XmlPageDetails.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import { BSpinner, BTable } from "bootstrap-vue";
import { TagsSchema } from "@/modules/genprox/modules/AIFM/modules/reg-reporting/models/Report";
import { Watch } from "vue-property-decorator";
import _ from 'lodash';
import Utils from '@/modules/shared/utils/utils';
import SygniLoader from '@/components/layout/SygniLoader.vue';

@Component({
  components: { PageDetails, BTable, BSpinner, SygniRoundedButton, SygniCheckbox, XmlPageDetails, SygniLoader },
})
export default class XmlPreview extends Vue {
  isLoading: boolean = true;
  title: string = 'XML Reader for Financial Statements';
  xml: string = '';
  structureCodes: any = {};
  parsedEngHtml: string = '';
  parsedPlHtml: string = '';
  activeLangEng: Boolean = false;
  rootTag: string = '';
  tableRow: Array<any> = [];
  emptyPreview: boolean = false;

  singlePlBlock: string | object = '';
  structurePl: Array<any> = [];
  tableColumnKeys: [] = [];
  singlePlTable: any = {
    fields: [
      { key: 'title', label: 'Tytuł' },
    ],
    items: []
  };

  get description() {
    return !this.hasId ? 'Upload your Financial Statement in XML format to review its content in readable form.' : ''
  }

  get xmlDoc() {
    const parser = new DOMParser();

    // if it's UTF-8 DOM change to UTF-8 - prevent error
    if (this.xml.charCodeAt(0) === 0xFEFF) {
      this.xml = this.xml.substr(1);
    }

    return parser.parseFromString(this.xml, "text/xml");
  }

  get hasId() {
    return !!this.$route.query?.id;
  }

  get isLoaded() {
    return this.xml.length > 0 || this.emptyPreview;
  }

  get routeId(): any {
    return this.$route.query.id;
  }

  getItemClass(item: string) {
    const div = document.createElement('div');
    div.innerHTML = item.trim();

    const isTableDiv = div.querySelector('.table-headline');

    if (isTableDiv) return 'table-header';
  }

  rowClass(item: any, type: any) {
    if (!item || type !== 'row') return
    if (item.nest === 0) {
      return 'nest-0'
    } else if (item.nest === 1) {
      return 'nest-1'
    } else if (item.nest === 2) {
      return 'nest-2'
    } else if (item.nest === 3) {
      return 'nest-3'
    } else if (item.nest === 4) {
      return 'nest-4'
    } else {
      return 'nest-5'
    }
  }

  getType(item: any) {
    return typeof item;
  }

  printPage() {
    window.print();
  }

  handleLangChange(): void {
    this.activeLangEng = !this.activeLangEng;
  }

  getXmlTagByName(tag: HTMLBaseElement | Document | Element, name: string): any {
    return tag !== undefined ? Array.from(tag.getElementsByTagName(name)) : false;
  }

  tableLoaded(fields: any) {
    if (fields.length === 7) {
      if (!this.$refs.table) {
        return;
      }

      // @ts-ignore
      this.$refs.table.forEach((i: any) => {
        var headers = i.$el.querySelectorAll('thead tr');
        if (headers.length > 1) {
          return;
        }

        var topHeader = document.createElement('tr');
        topHeader.innerHTML =
            '<th colspan="1"></th>' +
            '<th colspan="3" style="text-align: center;">Rok bieżący</th>' +
            '<th colspan="3" style="text-align: center;">Rok poprzedni</th>'
        headers[0].parentNode.insertBefore(topHeader, headers[0]);
      })
    }
  }

  async getXmlReportFile(id: string | string[]) {
    try {
      const data = await this.$store.dispatch('genprox/getXmlFile', id);
      this.isLoading = false;

      return data;
    } catch (e) {
      this.$router.push('/welcome');
    }
  }

  async getXmlStructureCodes(fileID: string) {
    return await this.$store.dispatch('regReporting/getXmlReportDescription', fileID);
  }

  setHeadlineTag(nest: number) {
    nest = nest + 1
    const tagName = (nest > 5 && nest < 1) ? 'p' : 'h' + nest;
    return document.createElement(tagName);
  }

  getTagName(tag: TagsSchema) {
    const { name, namespace } = tag;
    return `${namespace ? namespace + ':' : ''}${name}`;
  }

  getTagNameByTag(tag: string) {
    return tag.indexOf(':') ? tag.split(':')[1] : tag;
  }

  resetSingleBlocks(): void {
    this.singlePlBlock = '';
  }

  setCurrency(value: any) {
    return Number(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".", ",");
  }

  createAdditionalDOMElement(item: TagsSchema, value: any, isParentGroup: Boolean, nestIndex0: number, attrTag?: Boolean, classList?: string[], callbackFileName: string | null = null) {
    const { labelPl, children } = item;
    const plDiv = document.createElement('div');

    value = value?.textContent

    if (classList) {
      classList.forEach(className => {
        plDiv.classList.add(className);
      })
    }

    let plTextTag;
    plTextTag = this.setHeadlineTag(nestIndex0);
    plTextTag.textContent = item?.name && item?.name === 'Plik' ? labelPl : item?.name || labelPl;
    plDiv.append(plTextTag);

    plDiv.classList.add('xml-row');

    if (nestIndex0 > 0) {
      plDiv.style.paddingLeft = `${nestIndex0 * 20}px`;
    }

    plDiv.style.marginBottom = `16px`

    if (isParentGroup) {
      plDiv.style.marginTop = `16px`
    }

    if (!attrTag) {
      let plContent;
      if (!children.length) {
        if (item.name === 'Zawartosc') {
          let fileName = callbackFileName

          if (!fileName) {
            fileName = labelPl === null ? item?.name : labelPl
          }

          plContent = ` <button onClick="window.saveBinAttachment('${value}', '${fileName}')" class="sygni-rounded-button">Zapisz</button>`;
        } else {
          plContent = `<span> ${value}</span>`;
        }

        if (plTextTag) plTextTag.insertAdjacentHTML('beforeend', plContent);
      }
    }

    this.singlePlBlock = plDiv.outerHTML;

    this.structurePl.push(this.singlePlBlock);

    this.resetSingleBlocks()
  }

  createDOMElement(item: TagsSchema, value: string, isParentGroup: Boolean, nestIndex0: number, isRepetitve?: Boolean, attrTag?: Boolean, classList?: string[]) {
    const { labelPl, children } = item;
    const tagName = this.getTagName(item);

    const plDiv = document.createElement('div');

    if (classList) {
      classList.forEach(className => {
        plDiv.classList.add(className);
      })
    }

    if (isRepetitve) {
      const divWrapper = document.createElement('div');
      const lastRenderEl = this.structurePl[this.structurePl.length - 1];
      divWrapper.innerHTML = lastRenderEl.trim();
      divWrapper.querySelector('span').textContent += value;
      this.structurePl[this.structurePl.length - 1] = divWrapper.innerHTML;
    } else {
      let plTextTag;
      plTextTag = this.setHeadlineTag(nestIndex0);
      plTextTag.textContent = isRepetitve ? '' : (labelPl === null ? this.getTagNameByTag(tagName) : labelPl);
      plDiv.append(plTextTag);

      plDiv.classList.add('xml-row');

      if (nestIndex0 > 0) {
        plDiv.style.paddingLeft = `${nestIndex0 * 20}px`;
      }

      plDiv.style.marginBottom = `16px`

      if (isParentGroup) {
        plDiv.style.marginTop = `16px`
      }

      if (!attrTag) {
        let plContent;
        if (!children.length) {
          if (item.name === 'Zawartosc') {
            plContent = ` <button onClick="window.saveBinAttachment('${value}', '${labelPl === null ? this.getTagNameByTag(tagName) : labelPl}')" class="sygni-rounded-button">Zapisz</button>`;
          } else {
            plContent = `<span> ${value}</span>`;
          }

          if (plTextTag) plTextTag.insertAdjacentHTML('beforeend', plContent);
        }
      }

      this.singlePlBlock = plDiv.outerHTML;

      this.structurePl.push(this.singlePlBlock);
    }

    this.resetSingleBlocks();
  }

  saveRow(tagSchema: TagsSchema, parentDOM: HTMLBaseElement | Element, tableExtended: Boolean = false, nestIndex?: number) {
    if (tableExtended) {
      const { children } = tagSchema;

      const saveUserRow = (item: any) => {
        // const dtsfExist = Array.from(item.getElementsByTagName('dtsf:NazwaPozycji')).length > 0;
        // const ns1Exist = Array.from(item.getElementsByTagName('ns1:NazwaPozycji')).length > 0;
        // const ns2Exist = Array.from(item.getElementsByTagName('ns2:NazwaPozycji')).length > 0;
        // const ns3Exist = Array.from(item.getElementsByTagName('ns3:NazwaPozycji')).length > 0;
        // const emptyExist = Array.from(item.getElementsByTagName('NazwaPozycji')).length > 0;
        let label, values;

        // @ts-ignore
        label = Array.from(item.querySelectorAll('NazwaPozycji'))[0].textContent;
        // @ts-ignore
        values = Array.from(item.querySelectorAll('Kwoty'))[0].children;


        // if (dtsfExist) {
        //   // @ts-ignore
        //   label = Array.from(item.getElementsByTagName('dtsf:NazwaPozycji'))[0].textContent;
        //   // @ts-ignore
        //   values = Array.from(item.getElementsByTagName('dtsf:Kwoty'))[0].children;
        // }
        // if (ns1Exist) {
        //   // @ts-ignore
        //   label = Array.from(item.getElementsByTagName('ns1:NazwaPozycji'))[0].textContent;
        //   // @ts-ignore
        //   values = Array.from(item.getElementsByTagName('ns1:Kwoty'))[0].children;
        // }
        // if (ns2Exist) {
        //   // @ts-ignore
        //   label = Array.from(item.getElementsByTagName('ns2:NazwaPozycji'))[0].textContent;
        //   // @ts-ignore
        //   values = Array.from(item.getElementsByTagName('ns2:Kwoty'))[0].children;
        // }
        // if (ns3Exist) {
        //   // @ts-ignore
        //   label = Array.from(item.getElementsByTagName('ns3:NazwaPozycji'))[0].textContent;
        //   // @ts-ignore
        //   values = Array.from(item.getElementsByTagName('ns3:Kwoty'))[0].children;
        // }
        // if (emptyExist) {
        //   // @ts-ignore
        //   label = Array.from(item.getElementsByTagName('NazwaPozycji'))[0].textContent;
        //   // @ts-ignore
        //   values = Array.from(item.getElementsByTagName('Kwoty'))[0].children;
        // }

        const valuesArr = Array.from(values);

        const row: any = {
          title: label,
        };

        valuesArr.forEach((el: any) => {
          const tagName = el.tagName.replace(`${el.prefix}:`, '');
          let noNest: Boolean = false;

          Array.from(el.children).forEach((i: any) => {
            const childtagName = i.tagName.replace(`${i.prefix}:`, '');
            if(childtagName === 'KwotaA' || childtagName === 'KwotaB' || childtagName === 'KwotaC') noNest = true;
            // if (i.tagName === 'dtsf:KwotaA' || i.tagName === 'ns1:KwotaA' || i.tagName === 'ns2:KwotaA' || i.tagName === 'ns3:KwotaA' || i.tagName === 'KwotaA' || i.tagName === 'dtsf:KwotaB' || i.tagName === 'ns1:KwotaB' || i.tagName === 'ns2:KwotaB' || i.tagName === 'ns3:KwotaB' || i.tagName === 'KwotaB' || i.tagName === 'dtsf:KwotaC' || i.tagName === 'ns1:KwotaC' || i.tagName === 'ns2:KwotaC' || i.tagName === 'ns3:KwotaC' || i.tagName === 'KwotaC') noNest = true;
          })

          if (tagName === 'RB') {
            if (noNest) {
              Array.from(el.children).forEach((i: any) => {
                const tagName = i.tagName.replace(`${i.prefix}:`, '');

                if (tagName === 'KwotaA') {
                  row['RB:KwotaA'] = this.setCurrency(i.textContent);
                }
                if (tagName === 'KwotaB') {
                  row['RB:KwotaB'] = this.setCurrency(i.textContent);
                }
                if (tagName === 'KwotaC') {
                  row['RB:KwotaC'] = this.setCurrency(i.textContent);
                }

                if (!row['RB:KwotaA']) {
                  row['RB:KwotaA'] = '0,00';
                }
                if (!row['RB:KwotaB']) {
                  row['RB:KwotaB'] = '0,00';
                }
                if (!row['RB:KwotaC']) {
                  row['RB:KwotaC'] = '0,00';
                }
              })

            } else {
              let values = el.querySelectorAll('Kwota')[0];

              // if (dtsfExist) {
              //   values = el.getElementsByTagName('dtsf:Kwota')[0]
              // }
              // if (ns1Exist) {
              //   values = el.getElementsByTagName('ns1:Kwota')[0]
              // }
              // if (ns2Exist) {
              //   values = el.getElementsByTagName('ns2:Kwota')[0]
              // }
              // if (ns3Exist) {
              //   values = el.getElementsByTagName('ns3:Kwota')[0]
              // }
              // if (emptyExist) {
              //   values = el.getElementsByTagName('Kwota')[0];
              // }

              Array.from(values.children).forEach((i: any) => {
                const tagName = i.tagName.replace(`${i.prefix}:`, '');

                if (tagName === 'KwotaA') {
                  row['RB:KwotaA'] = this.setCurrency(i.textContent);
                }
                if (tagName === 'KwotaB') {
                  row['RB:KwotaB'] = this.setCurrency(i.textContent);
                }
                if (tagName === 'KwotaC') {
                  row['RB:KwotaC'] = this.setCurrency(i.textContent);
                }

                if (!row['RB:KwotaA']) {
                  row['RB:KwotaA'] = '0,00';
                }
                if (!row['RB:KwotaB']) {
                  row['RB:KwotaB'] = '0,00';
                }
                if (!row['RB:KwotaC']) {
                  row['RB:KwotaC'] = '0,00';
                }
              })
            }
          }

          if (tagName === 'RP') {
            if (noNest) {
              Array.from(el.children).forEach((i: any) => {
                const tagName = i.tagName.replace(`${i.prefix}:`, '');

                if (tagName === 'KwotaA') {
                  row['RP:KwotaA'] = this.setCurrency(i.textContent);
                }
                if (tagName === 'KwotaB') {
                  row['RP:KwotaB'] = this.setCurrency(i.textContent);
                }
                if (tagName === 'KwotaC') {
                  row['RP:KwotaC'] = this.setCurrency(i.textContent);
                }

                if (!row['RP:KwotaA']) {
                  row['RP:KwotaA'] = '0,00';
                }
                if (!row['RP:KwotaB']) {
                  row['RP:KwotaB'] = '0,00';
                }
                if (!row['RP:KwotaC']) {
                  row['RP:KwotaC'] = '0,00';
                }
              })
            } else {
              let values = el.querySelectorAll('Kwota')[0];

              // if (dtsfExist) {
              //   values = el.getElementsByTagName('dtsf:Kwota')[0]
              // }
              // if (ns1Exist) {
              //   values = el.getElementsByTagName('ns1:Kwota')[0]
              // }
              // if(ns2Exist) {
              //   values = el.getElementsByTagName('ns2:Kwota')[0];
              // }
              // if(ns3Exist) {
              //   values = el.getElementsByTagName('ns3:Kwota')[0];
              // }
              // if(emptyExist) {
              //   values = el.getElementsByTagName('Kwota')[0];
              // }

              Array.from(values.children).forEach((i: any) => {
                const tagName = i.tagName.replace(`${i.prefix}:`, '');

                if (tagName === 'KwotaA') {
                  row['RP:KwotaA'] = this.setCurrency(i.textContent);
                }
                if (tagName === 'KwotaB') {
                  row['RP:KwotaB'] = this.setCurrency(i.textContent);
                }
                if (tagName === 'KwotaC') {
                  row['RP:KwotaC'] = this.setCurrency(i.textContent);
                }

                if (!row['RP:KwotaA']) {
                  row['RP:KwotaA'] = '0,00';
                }
                if (!row['RP:KwotaB']) {
                  row['RP:KwotaB'] = '0,00';
                }
                if (!row['RP:KwotaC']) {
                  row['RP:KwotaC'] = '0,00';
                }
              })
            }
          }
        })

        return row;
      }

      const saveOtherRow = (item: any, tagSchema: any) => {
        const { children } = tagSchema;
        let label = '';

        children.forEach((el: any) => {
          if (el.name === item.tagName.split(':')[1] && el.namespace === item.tagName.split(':')[0]) {
            label = el.labelPl ? el.labelPl : this.getTagName(el);
          }
        })

        const row: any = {
          title: label,
        };

        const values: any = Array.from(item.children);

        values.forEach((el: any) => {
          const tagName = el.tagName.replace(`${el.prefix}:`, '');

          if (tagName === 'RB') {
            Array.from(el.children).forEach((i: any) => {
              const tagName = i.tagName.replace(`${i.prefix}:`, '');

              if (tagName === 'KwotaA') {
                row['RB:KwotaA'] = this.setCurrency(i.textContent);
              }
              if (tagName === 'KwotaB') {
                row['RB:KwotaB'] = this.setCurrency(i.textContent);
              }
              if (tagName === 'KwotaC') {
                row['RB:KwotaC'] = this.setCurrency(i.textContent);
              }

              if (!row['RB:KwotaA']) {
                row['RB:KwotaA'] = '0,00';
              }
              if (!row['RB:KwotaB']) {
                row['RB:KwotaB'] = '0,00';
              }
              if (!row['RB:KwotaC']) {
                row['RB:KwotaC'] = '0,00';
              }
            })
          }

          if (tagName === 'RP') {
            Array.from(el.children).forEach((i: any) => {
              const tagName = i.tagName.replace(`${i.prefix}:`, '');

              if (tagName === 'KwotaA') {
                row['RP:KwotaA'] = this.setCurrency(i.textContent);
              }
              if (tagName === 'KwotaB') {
                row['RP:KwotaB'] = this.setCurrency(i.textContent);
              }
              if (tagName === 'KwotaC') {
                row['RP:KwotaC'] = this.setCurrency(i.textContent);
              }

              if (!row['RP:KwotaA']) {
                row['RP:KwotaA'] = '0,00';
              }
              if (!row['RP:KwotaB']) {
                row['RP:KwotaB'] = '0,00';
              }
              if (!row['RP:KwotaC']) {
                row['RP:KwotaC'] = '0,00';
              }
            })
          }
        })

        return row;
      }

      if (children) {
        const rowPl: any = {
          title: tagSchema.labelPl,
          ['RB:KwotaA']: '',
          ['RB:KwotaB']: '',
          ['RB:KwotaC']: '',
          ['RP:KwotaA']: '',
          ['RP:KwotaB']: '',
          ['RP:KwotaC']: '',
          nest: nestIndex
        };

        if (rowPl.title) {
          const domChildren = Array.from(parentDOM.children);
          const valueTags = ['RB', 'RP'];
          let innerUserValues: any = [];
          let innerOtherValues: any = [];

          domChildren.forEach((tag) => {
            const childTagName = tag.tagName.replace(`${tag.prefix}:`, '');
            if (valueTags.includes(childTagName)) {
              if (tag.children.length === 0) {

                if (childTagName === 'RB') {
                  rowPl['RB:KwotaA'] = this.setCurrency(tag.textContent.toString());
                }

                if (childTagName === 'RP') {
                  rowPl['RP:KwotaA'] = this.setCurrency(tag.textContent.toString());
                }

                rowPl['RB:KwotaB'] = '0,00';
                rowPl['RB:KwotaC'] = '0,00';
                rowPl['RP:KwotaB'] = '0,00';
                rowPl['RP:KwotaC'] = '0,00';
              }
            }

            if (childTagName === 'Kwota') {
              const children = Array.from(tag.children);

              children.forEach(el => {
                const elTagName = el.tagName.replace(`${el.prefix}:`, '');
                if (elTagName === 'RB') {
                  const rBChildren = Array.from(el.children);

                  rBChildren.forEach(val => {
                    const valTagName = val.tagName.replace(`${val.prefix}:`, '');
                    if (valTagName === 'KwotaA') {
                      rowPl['RB:KwotaA'] = this.setCurrency(val.textContent);
                    }
                    if (valTagName === 'KwotaB') {
                      rowPl['RB:KwotaB'] = this.setCurrency(val.textContent);
                    }
                    if (valTagName === 'KwotaC') {
                      rowPl['RB:KwotaC'] = this.setCurrency(val.textContent);
                    }

                    if (!rowPl['RB:KwotaA']) {
                      rowPl['RB:KwotaA'] = '0,00';
                    }
                    if (!rowPl['RB:KwotaB']) {
                      rowPl['RB:KwotaB'] = '0,00';
                    }
                    if (!rowPl['RB:KwotaC']) {
                      rowPl['RB:KwotaC'] = '0,00';
                    }
                  })
                }

                if (elTagName === 'RP') {
                  const rBChildren = Array.from(el.children);

                  rBChildren.forEach(val => {
                    const valTagName = val.tagName.replace(`${val.prefix}:`, '');
                    if (valTagName === 'KwotaA') {
                      rowPl['RP:KwotaA'] = this.setCurrency(val.textContent);
                    }
                    if (valTagName === 'KwotaB') {
                      rowPl['RP:KwotaB'] = this.setCurrency(val.textContent);
                    }
                    if (valTagName === 'KwotaC') {
                      rowPl['RP:KwotaC'] = this.setCurrency(val.textContent);
                    }

                    if (!rowPl['RP:KwotaA']) {
                      rowPl['RP:KwotaA'] = '0,00';
                    }
                    if (!rowPl['RP:KwotaB']) {
                      rowPl['RP:KwotaB'] = '0,00';
                    }
                    if (!rowPl['RP:KwotaC']) {
                      rowPl['RP:KwotaC'] = '0,00';
                    }
                  })
                }
              })
            }

            if (childTagName === 'PozycjaUzytkownika') {
              const row = saveUserRow(tag)
              row.nest = nestIndex + 1;
              innerUserValues.push(row)
            }

            if (childTagName === 'Pozostale') {
              const row = saveOtherRow(tag, tagSchema);
              row.nest = nestIndex + 1;
              innerOtherValues.push(row)
            }
          })

          this.singlePlTable.items.push(rowPl);

          if (innerUserValues.length) {
            innerUserValues.forEach((row: any) => {
              this.singlePlTable.items.push(row);
            })
          }

          if (innerOtherValues.length) {
            innerOtherValues.forEach((row: any) => {
              this.singlePlTable.items.push(row);
            })
          }
        }
      }
    } else {
      if (parentDOM !== undefined) {
        const dom = parentDOM.children;
        const { labelPl } = tagSchema;
        let rowPl: any = {};
        const userTag = this.userTagsAvailable(parentDOM);

        // handle tags without values
        if (nestIndex === 0 && labelPl) {
          let hasValues = false;

          Array.from(dom).forEach(tag => {
            const tagName = tag.tagName.replace(`${tag.prefix}:`, '');
            if (tagName === 'KwotaA' || tagName === 'KwotaB') {
              hasValues = true;
            }
          })

          if (!hasValues) {
            // const isDtsf = parentDOM.tagName.includes('asi:');
            // const isNs1 = parentDOM.tagName.includes('ns2:');
            // const isNs2 = parentDOM.tagName.includes('ns3:');
            // const isNs3 = parentDOM.tagName.includes('ns4:');
            // const isEmpty = (!isDtsf && !isNs1 && !isNs2 && !isNs3) ? true : false;

            rowPl.title = `${labelPl === null ? '' : labelPl}`;

            if(parentDOM.prefix === 'asi') {
              rowPl['dtsf:KwotaA'] = '';
              rowPl['dtsf:KwotaB'] = '';
            } else {
              const valueTag = parentDOM?.querySelector('KwotaA')

              if (valueTag && parentDOM.prefix !== valueTag?.prefix) {
                rowPl[`${valueTag?.prefix ? `${valueTag.prefix}:` : ''}KwotaA`] = '';
                rowPl[`${valueTag?.prefix ? `${valueTag.prefix}:` : ''}KwotaB`] = '';
              } else {
                rowPl[`${parentDOM.prefix ? `${parentDOM.prefix}:` : ''}KwotaA`] = '';
                rowPl[`${parentDOM.prefix ? `${parentDOM.prefix}:` : ''}KwotaB`] = '';
              }
            }

            // if (isDtsf) {
            //   rowPl['dtsf:KwotaA'] = '';
            //   rowPl['dtsf:KwotaB'] = '';
            // }
            // if (isNs1) {
            //   rowPl['ns1:KwotaA'] = '';
            //   rowPl['ns1:KwotaB'] = '';
            // }
            // if(isNs2) {
            //   rowPl['ns2:KwotaA'] = '';
            //   rowPl['ns2:KwotaB'] = '';
            // }
            // if(isNs3) {
            //   rowPl['ns3:KwotaA'] = '';
            //   rowPl['ns3:KwotaB'] = '';
            // }
            // if(isEmpty) {
            //   rowPl['KwotaA'] = '';
            //   rowPl['KwotaB'] = '';
            // }
            rowPl.nest = 0;

            if (!_.isEmpty(rowPl)) {
              this.singlePlTable.items.push(rowPl);
              rowPl = {};
            }
          }
        }

        // dump user tag
        if (userTag) {
          const labelEl = Array.from(dom).find(el => el.tagName.includes('NazwaPozycji'));
          const valuesEl = Array.from(dom).find(el => el.tagName.includes('KwotyPozycji'));

          rowPl.title = labelEl.textContent;
          Array.from(valuesEl.children).forEach(val => {
            rowPl[val.tagName] = this.setCurrency(val.textContent.toString());
          })
          rowPl.nest = nestIndex;
        } else {
          Array.from(dom).forEach(i => {
            // prevent dump user tag
            if (!parentDOM.tagName.includes('KwotyPozycji') || i.tagName.includes('PozycjaUszczegolawiajaca')) {
              // @ts-ignore
              const isValuedTag = this.tableColumnKeys.includes(i.tagName);

              if (isValuedTag) {
                const value = i.textContent;
                rowPl.title = `${labelPl === null ? i.tagName : labelPl}`;
                rowPl.nest = nestIndex;

                rowPl[i.tagName] = this.setCurrency(value.toString());
              }
            }
          })
        }

        if (!_.isEmpty(rowPl)) {
          this.singlePlTable.items.push(rowPl);
        }
      }
    }
  }

  getTableHeaders(tagSchema: TagsSchema): any {
    const headersPl: any[] = [];

    const structureDive = (schema: any) => {
      schema.children.forEach((tag: any) => {
        const labelsAvailable = tag.name === 'KwotaA' || tag.name === 'KwotaB' || tag.name === 'KwotaB1';

        if (labelsAvailable && tag.labelPl !== null) {
          const tagName = this.getTagName(tag);
          const { labelPl } = tag;

          const headerPlItem = { key: tagName, label: labelPl };

          const isFound = headersPl.some(element => {
            return element.key === tagName && element.label === labelPl;
          });

          if (!isFound) {
            headersPl.push(headerPlItem)
          }
        } else {
          structureDive(tag)
        }
      })
    }
    structureDive(tagSchema)

    const result = {
      pl: headersPl,
    };

    result.pl.forEach((i) => {
      // @ts-ignore
      this.tableColumnKeys.push(i.key)
    })

    return result
  }

  userTagsAvailable(domEl: any) {
    return domEl.tagName.includes('PozycjaUszczegolawiajaca');
  }

  getTableBody(tagSchema: TagsSchema, nestIndex: number, parentDOM: HTMLBaseElement | Element) {
    const { children } = tagSchema;

    if (children.length) {
      children.forEach(childSchema => {
        const tagName = this.getTagName(childSchema);

        if (parentDOM !== undefined) {
          const childDom = parentDOM.getElementsByTagName(tagName)[0];

          this.saveRow(childSchema, childDom, false, nestIndex)
          this.getTableBody(childSchema, nestIndex + 1, childDom);
        }
      })
    }
  }

  reduceColumns() {
    const reduce = (item: any) => {
      const firstItem = item.items[0];

      if (firstItem) {
        const headers = item.fields;
        const keys = Object.keys(firstItem);

        headers.forEach((header: any, index: number) => {
          if (!keys.includes(header.key)) {
            if (index > -1) {
              headers.splice(index, 1);
            }
          }
        })
      }
    }
    reduce(this.singlePlTable);
  }

  getTableExtendHeaders() {
    return {
      pl: [
        {
          key: 'RB:KwotaA',
          label: 'Wartość łączna'
        },
        {
          key: 'RB:KwotaB',
          label: 'z zysków kapitałowych'
        },
        {
          key: 'RB:KwotaC',
          label: 'z innych źródeł przychodów'
        },
        {
          key: 'RP:KwotaA',
          label: 'Wartość łączna'
        },
        {
          key: 'RP:KwotaB',
          label: 'z zysków kapitałowych'
        },
        {
          key: 'RP:KwotaC',
          label: 'z innych źródeł przychodów'
        }
      ],
    };
  }

  getExtendedTableBody(tagSchema: TagsSchema, nestIndex: number, parentDOM: HTMLBaseElement | Element) {
    const { children } = tagSchema;

    if (children.length) {
      children.forEach(childSchema => {
        const tagName = this.getTagName(childSchema);

        if (parentDOM !== undefined) {
          const childDom = parentDOM.getElementsByTagName(tagName)[0];
          this.saveRow(childSchema, childDom, true, nestIndex)
          this.getExtendedTableBody(childSchema, nestIndex + 1, childDom);
        }
      })
    }
  }

  renderTableBlock(tagSchema: TagsSchema, nestIndex: number, parentDOM: HTMLBaseElement | Element, firstIterate: Boolean = false, extendedTable: Boolean = false, forceNestIndex: any) {
    if (firstIterate) {
      // table headline
      const parentDOMTagName = parentDOM.tagName.replace(`${parentDOM.prefix}:`, '');
      const tableBreakClass = parentDOMTagName === 'Bilans' || parentDOMTagName === 'BilansJednostkaMala' || parentDOMTagName == 'BilansJednostkaInna' || parentDOMTagName === 'BilansJednostkaMikro' || parentDOMTagName === 'ZestZmianWKapitale' || parentDOMTagName === 'InformacjaDodatkowaDotyczacaPodatkuDochodowego' || parentDOMTagName === 'RZiS' || parentDOMTagName === 'RZiSJednostkaMikro' ? ['table-headline'] : [];
      this.createDOMElement(tagSchema, '', false, parentDOMTagName === 'InformacjaDodatkowaDotyczacaPodatkuDochodowego' ? 1 : nestIndex, false, false, tableBreakClass)
    }

    if (extendedTable) {
      const headers = this.getTableExtendHeaders();

      if (headers.pl.length) {
        const { pl } = headers;

        pl.forEach((header: any) => {
          this.singlePlTable.fields.push(header)
        })
      }

      this.getExtendedTableBody(tagSchema, forceNestIndex === 0 ? 0 : nestIndex, parentDOM);
    } else {
      const headers = this.getTableHeaders(tagSchema);

      if (headers.pl.length) {
        const { pl } = headers;

        pl.forEach((header: string) => {
          this.singlePlTable.fields.push(header)
        })
      }

      this.getTableBody(tagSchema, forceNestIndex === 0 ? 0 : nestIndex, parentDOM);
    }

    this.singlePlBlock = this.singlePlTable;

    this.reduceColumns();

    this.structurePl.push(this.singlePlBlock);

    this.resetSingleBlocks();

    this.singlePlTable = {
      fields: [
        { key: 'title', label: 'Tytuł' },
      ],
      items: []
    };
  }

  renderSingleTag(item: TagsSchema, nestIndex0: number, parent: HTMLBaseElement | Element, isParagraph?: Boolean, attrTag?: Boolean) {
    const tagName = this.getTagName(item);
    const isParentGroup = nestIndex0 === 0;
    let tagDocs;

    // prevent invalid xml crash
    if (parent !== undefined) {
      if (isParentGroup) {
        tagDocs = Array.from(this.xmlDoc.getElementsByTagName(tagName));
      } else {
        tagDocs = Array.from(parent.getElementsByTagName(tagName));
      }

      tagDocs.forEach(i => {
        const elTagName = tagName.replace(`${i.prefix}:`, '');
        const value = i.textContent;

        if (elTagName === 'NazwaFirmy') {
          this.title = i.textContent;
        }

        let tableBreakClass = elTagName === 'RZiS' || tagName === 'RachPrzeplywow' ? ['table-headline'] : [];

        this.createDOMElement(item, value, isParentGroup, nestIndex0, false, attrTag, tableBreakClass)
      })
    }
  }

  renderTextBlock(tagSchema: TagsSchema, nestIndex: number, parentDOM: HTMLBaseElement | Element) {
    const tagName = this.getTagName(tagSchema);
    const tagDoc = this.getXmlTagByName(parentDOM, tagName)[0];
    const { children } = tagSchema;
    const tagLabels: any = [];

    children.forEach(i => {
      const key = `${i.namespace}:${i.name}`
      const labels = {
        pl: i.labelPl,
      }

      tagLabels.push({ key, labels })
    })

    let lastTag = '';

    Array.from(tagDoc.children).forEach((i: any, index) => {
      const value = i.textContent;
      const isRepetitve = lastTag === tagLabels[index].key;

      this.createDOMElement(children[index], value, false, nestIndex, isRepetitve)
      lastTag = tagLabels[index].key;
    })
  }

  renderTags(tagSchema: TagsSchema, nestIndex: number, parent: HTMLBaseElement | Element, nest: number) {
    const { children } = tagSchema;
    const tagName = this.getTagName(tagSchema);
    const isRootElement = nestIndex === 0;
    let tagDoc: any;

    if (tagName === 'ds:Signature') return;

    if (isRootElement) {
      tagDoc = this.getXmlTagByName(this.xmlDoc, tagName)[0];
    } else {
      tagDoc = this.getXmlTagByName(parent, tagName)[0];
    }

    if (nestIndex === 0) this.rootTag = tagName;

    const elTagName = tagName.replace(`${tagSchema.namespace}:`, '');

    if (elTagName === 'Bilans' || elTagName === 'BilansJednostkaMala' || elTagName === 'BilansJednostkaInna' || elTagName === 'BilansJednostkaMikro' || elTagName === 'RZiSPor' || elTagName === 'RZiSJednostkaMikro' || elTagName === 'RZiSKalk' || elTagName === 'ZestZmianWKapitale' || elTagName === 'PrzeplywyPosr' || elTagName === 'InformacjaDodatkowaDotyczacaPodatkuDochodowego') {
      let forceNestIndex: any = elTagName === 'RZiSPor' || elTagName === 'RZiSKalk' || elTagName === 'PrzeplywyPosr' ? 0 : false;

      if (elTagName === 'InformacjaDodatkowaDotyczacaPodatkuDochodowego') {
        forceNestIndex = 1
      }

      this.renderTableBlock(tagSchema, nestIndex, tagDoc, true, elTagName === 'InformacjaDodatkowaDotyczacaPodatkuDochodowego', forceNestIndex)
    } else if (children.length > 0) {
      this.renderSingleTag(tagSchema, nestIndex, parent);
      const isParagraph = tagName.includes('P_7');

      if (isParagraph) {
        this.renderTextBlock(tagSchema, nestIndex, parent);
      } else {
        children.forEach(childTagName => {
          this.renderTags(childTagName, nestIndex + 1, tagDoc, nest++)
        })
      }

    } else if (children.length === 0) {
      if (tagDoc !== undefined && tagDoc.getAttributeNames().length) {
        this.renderSingleTag(tagSchema, nestIndex, parent, false, true);
        const attributes = tagDoc.getAttributeNames();

        const createFromAttr = (pl: string, value: string, nest: number) => {
          const attrPlDiv = document.createElement('div');

          let attrPlTextTag;
          attrPlTextTag = this.setHeadlineTag(nest);
          attrPlTextTag.textContent = `${pl}: `;
          attrPlDiv.append(attrPlTextTag);

          attrPlDiv.classList.add('xml-row');

          if (attrPlTextTag) attrPlTextTag.insertAdjacentHTML('beforeend', `<span>${value}</span>`);

          if (nest > 0) {
            attrPlDiv.style.paddingLeft = `${nest * 42}px`;
          }

          attrPlDiv.style.marginBottom = `16px`

          this.singlePlBlock = attrPlDiv.outerHTML;

          this.structurePl.push(this.singlePlBlock);

          this.resetSingleBlocks();
        }

        const nest = nestIndex + 1;

        attributes.forEach((attribute: any) => {
          const key = attribute;
          const value = tagDoc.getAttribute(attribute)

          createFromAttr(key, value, nest)
        })

        createFromAttr('valueOf_', tagDoc.textContent, nest)
      } else {
        this.renderSingleTag(tagSchema, nestIndex, parent);
      }
    }
  }

  renderAdditionalInformation(tagSchema: TagsSchema) {
    // add additional information section header
    this.createDOMElement(tagSchema, '', false, 0, false, false, null)
    
    // add additional information section subheader
    this.createDOMElement(tagSchema, '', false, 1, false, false, null)

    tagSchema?.children?.forEach((childTag: TagsSchema, i: number) => {
      const tagName = this.getTagName(childTag);
      const tagDoc = this.getXmlTagByName(this.xmlDoc, tagName)

      if (tagDoc?.length > 1) {
        this.renderAdditionalTag(childTag, tagDoc[i]);
      } else {
        this.renderTags(childTag, 0, tagDoc[0], 0);
      }
    })
  }

  renderAdditionalTag(childTag: TagsSchema, tagDoc: any) {

    if (childTag?.children?.length) {
      childTag?.children?.forEach((tag: TagsSchema) => {
        const tagName = this.getTagName(tag);
        const foundTag = this.getXmlTagByName(tagDoc, tagName)[0]

        if (foundTag) {
          if (tag?.name === 'Opis') {
            this.createAdditionalDOMElement(tag, foundTag, false, 2, false, null)
          } else if (tag?.name === 'Plik') {
            this.createAdditionalDOMElement(tag, foundTag, false, 2, false, null)
            let callbackFileName: string | null = null
            tag?.children?.forEach((childTag: TagsSchema) => {
              const tagName = this.getTagName(childTag);
              const foundTag = this.getXmlTagByName(tagDoc, tagName)[0]

              if (childTag?.name === 'Zawartosc') {
                this.createAdditionalDOMElement(childTag, foundTag, false, 3, false, null, callbackFileName)
                callbackFileName = null
              } else {
                this.createAdditionalDOMElement(childTag, foundTag, false, 3, false, null, null)
                callbackFileName = foundTag?.textContent || null
              }
            })
          }
        }
      })
    }
  }

  async renderXml() {
    await this.structureCodes.forEach((tagSchema: TagsSchema) => {
      const tagName = this.getTagName(tagSchema);
      const tagDoc = this.getXmlTagByName(this.xmlDoc, tagName);
      if (!(tagSchema?.name === "DodatkoweInformacjeIObjasnieniaJednostkaInna" || 
        tagSchema?.name === "DodatkoweInformacjeIObjasnieniaJednostkaMala" ||
        tagSchema?.name === "DodatkoweInformacjeIObjasnieniaZalacznik1UoR" ||
        tagSchema?.name === "DodatkoweInformacjeIObjasnieniaBank" ||
        tagSchema?.name === "InformacjeUzupelniajaceDoBilansuJednostkaMikro" ||
        tagSchema?.name === "DodatkoweInformacjeIObjasnieniaMSKOK" ||
        tagSchema?.name === "DodatkoweInformacjeIObjasnieniaDomMaklerski" ||
        tagSchema?.name === "DodatkoweInformacjeIObjasnieniaZakladUbezpieczen"
      )) {
        this.renderTags(tagSchema, 0, tagDoc[0], 0);
      } else {
        this.renderAdditionalInformation(tagSchema)
      }
    })

    return true;
  }

  async getXmlData(fileId?: any) {
    this.xml = '';
    this.parsedEngHtml = '';
    this.parsedPlHtml = '';
    const data = await this.getXmlStructureCodes(fileId ? fileId : this.$route.query.id);
    const result = Utils.complexMultipleFind(data.elements[0].children, ['ASI', 'JednostkaInna']);
    this.structureCodes = result ? result.children : data.elements[0].children;

    this.xml = await this.getXmlReportFile(fileId ? fileId : this.$route.query.id);

    if (this.xml) {
      const renderCompleted = await this.renderXml();

      if (renderCompleted) {
        this.isLoading = false;
      }
    } else {
      this.$router.push('/welcome');
    }
  }

  resetData() {
    this.singlePlBlock = '';
    this.structurePl = [];
    this.title = 'XML Reader for Financial Statements';
    this.tableColumnKeys = [];
    this.singlePlTable = {
      fields: [
        { key: 'title', label: 'Tytuł' },
      ],
      items: []
    };
  }

  initXmlPreview() {
    function base64ToArrayBuffer(base64: any) {
      const binaryString = window.atob(base64);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    }

    const saveByteArray = (function () {
      const a = document.createElement("a");
      document.body.appendChild(a);
      // @ts-ignore
      a.style = "display: none";
      return function (data: any, name: string) {
        const blob = new Blob(data, { type: "octet/stream" }),
            url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = name;
        a.click();
        window.URL.revokeObjectURL(url);
      };
    }());

    // @ts-ignore
    window.saveBinAttachment = (bin: any, name: any) => {
      const bytes = base64ToArrayBuffer(bin);
      saveByteArray([bytes], `${name?.replaceAll('.pdf', '')}.pdf`)
    }
  }

  async mounted() {
    if (this.hasId) this.isLoading = true;

    if (!this.hasId) {
      this.emptyPreview = true;
      this.isLoading = false;
      this.resetData();
    }

    if (!this.emptyPreview) {
      this.initXmlPreview();
      await this.getXmlData();
    }
  }

  @Watch('routeId') async onRouteIdChange() {
    this.resetData();

    if (this.routeId?.length) {
      this.isLoading = true;
      this.emptyPreview = false;
      this.initXmlPreview();
      await this.getXmlData(this.routeId);

    } else {
      this.isLoading = false;
    }
  }
}
